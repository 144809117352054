import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import LinkOut from '../components/linkout';
import Header from '../components/header';
import WorkArticle from '../components/work-article';
import WorkHeading from '../components/work-heading';

const WorkPage = () => (
  <React.Fragment>
    <SEO title="Work by Abramski" path="work" />
    <Header>
      <nav>
        <ul>
          <li><Link className="btn btn-small" to="/">Home</Link></li>
          <li><Link className="btn btn-small" to="/contact">Contact</Link></li>
        </ul>
      </nav>
    </Header>
    <Layout>
      <h2><span className="alt">W</span>ork</h2>
      <section>
        <WorkHeading sub="to Get Work" />
        <WorkArticle
          title="XFive PMR"
          link="https://pmr.by.abram.ski"
          src="https://github.com/robertabramski/xfive-test">
          I designed and developed a POC React app from
          a <LinkOut href="https://github.com/robertabramski/xfive-test/blob/master/SPEC.md">document</LinkOut>.
        </WorkArticle>
        <WorkArticle
          title="Wildrook SCR"
          link="https://scr.by.abram.ski"
          src="https://github.com/robertabramski/wildrook-test">
          I designed and developed a POC Angular app from
          a <LinkOut href="https://github.com/robertabramski/wildrook-test/blob/master/src/assets/response.json">data source</LinkOut>.
        </WorkArticle>
      </section>
      <section>
        <WorkHeading sub="to Earn Money" />
        <WorkArticle title="JRM Construction" link="https://www.jrmcm.com">
          I am designing and developing internal productivity applications with Angular and C#.
        </WorkArticle>
        <WorkArticle title="Human Sounds" link="https://www.humansounds.net">
          I designed and developed an interactive audio application using Vue, Django and Web Sockets.
        </WorkArticle>
        <WorkArticle title="New York Social Diary" link="https://newyorksocialdiary.com">
          I designed and developed visual enhancements for the existing events calendar page.
        </WorkArticle>
        <WorkArticle title="Curafied">
          I designed and developed enhancements for the public-facing pages of the existing
          Angular site.
        </WorkArticle>
        <WorkArticle title="ArtTable" link="https://arttable.org">
          I developed a WordPress theme using Sass and Underscores and integrated with
          Neon CRM.
        </WorkArticle>
        <WorkArticle title="TrueFacet" link="https://www.truefacet.com">
          I developed a parallax microsite for the holidays along with a few basic enhancements
          to existing pages.
        </WorkArticle>
        <WorkArticle title="Juicero">
          I developed the eCommerce platform for a startup that created an Internet
          connected juicer.
        </WorkArticle>
        <WorkArticle title="Sumazi">
          I designed and developed the Social Intelligence Bar as well as the Sumazi
          Analytics platform.
        </WorkArticle>
      </section>
      <section>
        <WorkHeading sub="for Myself" />
        <WorkArticle title="Infinite Statement Machine" link="https://ism.by.abram.ski">
          I created a CLI-driven microblogging platform that generates
          responsive text designs using NestJS and Angular.
        </WorkArticle>
        <WorkArticle title="CEO Pay Cut" link="https://ceopaycut.org.at.abram.ski">
          I created a petition site to challenge growing income inequality using PHP and CodeIgniter.
        </WorkArticle>
        <WorkArticle title="Robert Abramski" link="http://robertabramski.com.at.abram.ski">
          I created a theme and plugins with PHP and WordPress to display my work and
          my blog posts.
        </WorkArticle>
        <WorkArticle title="Dribbble Draft" link="http://draft.robertabramski.com">
          I created a scrolling page to get drafted on Dribbble using basic HTML and CSS.
        </WorkArticle>
        <WorkArticle title="Sassybars" link="https://github.com/robertabramski/sassybars">
          I created a static site generator using Bulbo, Handlebars and Sass.
        </WorkArticle>
        <WorkArticle title="Revolution Emailer" link="http://revolution.robertabramski.com">
          I created a promotional page for cover letters using jQuery and Less.
        </WorkArticle>
      </section>
      <section>
        <WorkHeading sub="That No Longer Works" />
        <WorkArticle title="Randumb TV" link="http://randumb.tv.at.abram.ski">
          I created a site that endlessly plays random related YouTube video using CodeIgniter and ActionScript.
        </WorkArticle>
        <WorkArticle title="Cyntaxic" link="https://github.com/robertabramski/cyntaxic">
          I created a framework following MVC, Composite and Observer patterns using ActionScript for Flex and Flash.
        </WorkArticle>
      </section>
    </Layout>
  </React.Fragment>
);

export default WorkPage;
