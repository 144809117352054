import React from 'react';
import LinkOut from './linkout';

const WorkArticle = ({title, link, src, children}) => (
  <article>
    <h4>
      <LinkOut href={link || null}>{title}</LinkOut>
      {src ? <LinkOut href={src}>{`</src>`}</LinkOut> : null}
    </h4>
    <p>{children}</p>
  </article>
);

export default WorkArticle;
